import React from 'react';
import {Form, Input, Button, InputNumber, Popover} from 'antd';
import {DeleteOutlined, ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons';
import PhoneNumber from '../FormElements/PhoneNumber/PhoneNumber';
import {digitRankFormatter} from '../Helpers/Utils';
import styles from './RenderValuesArrayField.module.scss';
import cn from 'classnames';

const RenderValuesArrayField = (item, cnf, createItemRules) => {
  const {
    fieldType: {id},
  } = item;

  const renderField = () => {
    switch (id) {
      case 23:
      case 24:
        return (
          <InputNumber
            style={{width: '100%'}}
            disabled={item?.options?.gui_editable !== true}
            decimalSeparator={id !== 24 && ','}
            formatter={(value) => digitRankFormatter(value, id)}
          />
        );
      default:
        if (item.options && item.options.phone_mask) {
          return <PhoneNumber disabled={item?.options?.gui_editable !== true} />;
        }
        return <Input disabled={item?.options?.gui_editable !== true} />;
    }
  };

  const fieldTitleRender = item.description ? (
    <Popover content={item.description} placement="topLeft">
      {<strong>{item.caption}</strong>}
      {item?.options?.gui_editable !== true && (
        <span className="colorExclamationPoint">
          {' '}
          <ExclamationCircleOutlined className="ml-2" />
          Запрещено редактирование данного поля
        </span>
      )}
    </Popover>
  ) : (
    <>
      <strong>{item?.caption}</strong>
      {item?.options?.gui_editable !== true && (
        <span className="colorExclamationPoint">
          {' '}
          <ExclamationCircleOutlined className="ml-2" /> Запрещено редактирование данного поля
        </span>
      )}
    </>
  );

  const itemRules = createItemRules(cnf, item.required, item.fieldType.id);

  return (
    <Form.List name={item.origin}>
      {(fields, {add, remove}, {errors}) => {
        const allowedToAddValue = item?.options?.gui_editable;
        return (
          <>
            {fields.map((field, index) => (
              <div className={styles.itemField}>
                <Form.Item
                  {...field}
                  rules={itemRules}
                  label={index === 0 ? fieldTitleRender : ''}
                  className={cn('col-12', [styles.formItem])}
                >
                  {renderField()}
                </Form.Item>
                {index !== 0 ? (
                  <DeleteOutlined className={styles.deleteIcon} onClick={() => remove(field.name)} />
                ) : null}
              </div>
            ))}
            <Form.Item>
              <Button type="link" onClick={() => add()} icon={<PlusOutlined />} disabled={!allowedToAddValue}>
                Добавить значение
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

export default RenderValuesArrayField;
