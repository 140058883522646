import React, {Component} from 'react';
import Fields from '../CardWidgets/Fields';
import Attributes from '../CardWidgets/Attributes/Attributes';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {BaseDiv} from '../Themes/Components';

class DetailDraftRedactor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [
        {value: 1, label: 'Строка (до 255 символов)'},
        {value: 11, label: 'Текст'},
        {value: 5, label: 'Файл'},
        {value: 13, label: 'Гиперссылка (URL)'},
        {value: 2, label: 'Целое число'},
        {value: 3, label: 'Число с плавающей точкой'},
        {value: 4, label: 'Ссылка на справочник'},
        {value: 14, label: 'Выбор из списка'},
        {value: 10, label: 'Логическое'},
        {value: 7, label: 'Дата и время'},
        {value: 12, label: 'Детализация'},
      ],
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {draftId, detailId, catalogIdentifier},
      },
    } = this.props;
    this.setState(
      {
        uuid: draftId,
        detailUuid: detailId,
        catalogIdentifier,
        loading: true,
      },
      () => {
        this.getDetailItems();
      }
    );
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: {draftId, detailId, catalogIdentifier},
      },
    } = this.props;
    if (prevProps.match !== this.props.match) {
      this.setState(
        {
          uuid: draftId,
          detailUuid: detailId,
          catalogIdentifier,
        },
        () => {
          this.getDetailItems();
        }
      );
    }
  }

  getDetailItems = async () => {
    const {uuid, detailUuid} = this.state;
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalog-draft/${uuid}/fields/${detailUuid}`,
        method: 'get',
      });

      if (data) {
        this.setState({data, loading: false});
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };

  onAttributesSave = () => {
    this.setState({loading: true}, () => this.getDetailItems());
  };

  render() {
    const {data, uuid, detailUuid, loading, options, catalogIdentifier} = this.state;
    const {status, location, history, match} = this.props;
    return (
      <BaseDiv sName="containerWhite">
        <Attributes
          loading={loading}
          uuid={uuid}
          detailUuid={detailUuid}
          operation={data && data.operation}
          options={options}
          status={status}
          data={[
            {
              caption: 'Тип поля',
              value: data && data.fieldType && data.fieldType.id,
              type: 'Select',
              origin: 'fieldTypeId',
            },
            {caption: 'Наименование', value: data && data.caption, type: 'String', origin: 'caption'},
            {caption: 'Наименование в БД', value: data && data.origin, type: 'String', origin: 'origin'},
            {
              caption: 'Описание',
              value: data && data.description,
              type: 'Text',
              origin: 'description',
            },
            {
              caption: 'Редактируемое через GUI',
              value: data && data.options && data.options.gui_editable,
              type: 'Checkbox',
              origin: 'options.gui_editable',
            },
            {
              caption: 'Устаревшее поле',
              value: data && data.options && data.options.old,
              type: 'Checkbox',
              origin: 'options.old',
            },
            {
              caption: 'Правила поля',
              value: data && data.options && data.options.rules,
              type: 'Text',
              origin: 'options.rules',
            },
            {
              caption: 'Порядок расположения',
              value: data && data.fieldOrder,
              type: 'Number',
              origin: 'fieldOrder',
            },
          ]}
          onSave={this.onAttributesSave}
        />
        <Fields
          status={status}
          draftAction={data && data.operation}
          catalogIdentifier={catalogIdentifier}
          detail={true}
          location={location}
          history={history}
          match={match}
        />
      </BaseDiv>
    );
  }
}

export default DetailDraftRedactor;
