export const modalFields = [
  {title: 'Тип поля', origin: 'fieldType.caption'},
  {title: 'Наименование в БД', origin: 'origin'},
  {title: 'Наименование поля', origin: 'caption'},
  {title: 'Описание', origin: 'description'},
  {title: 'Используется для контекстного поиска', origin: 'options.context_search'},
  {title: 'Отображать в списковой форме по умолчанию', origin: 'options.default_in_list'},
  {title: 'Редактируемое через GUI', origin: 'options.gui_editable'},
  {title: 'Обязательное на уровне БД', origin: 'required'},
  {title: 'Уникальное при импорте', origin: 'importUnique'},
  {title: 'Устаревшее поле', origin: 'options.old'},
];
