import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Dropdown, Space, Menu, Typography, Modal} from 'antd';
import {DownOutlined, UserOutlined} from '@ant-design/icons';

import './HeaderMDM.scss';

import HelpInfoModal from '../HelpInfoModal/HelpInfoModal';
import {headerMDM} from '../Helpers/constants';

/** Header
 * @param  keycloakLogout - функция  выхода из приложения */
const HeaderMDM = ({keycloakLogout}) => {
  /** Начальное состояние модального окна */
  const [visible, setVisible] = useState(false);
  /** userName авторизованного пользователя */
  const userName = useSelector((state) => state?.user.userName);
  /** Меню для пользователя  */
  const widgetMenu = (
    <Menu>
      <Menu.Item>
        <a href={`${window._env.REACT_APP_SSO_CONFIG_URL}/realms/master/account/?referrer=security-admin-console`}>Профиль</a>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => setVisible(true)}>Помощь</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={keycloakLogout}>Выход</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="header-mdm">
        <div className="header-mdm__title">{headerMDM.headTitle}</div>
        <div className="header-mdm__border"></div>
        <Dropdown overlay={widgetMenu} className="header-mdm__dropdown">
          <Space>
            <UserOutlined />
            <Typography className="header-mdm__dropdown--user">{userName}</Typography>
            <DownOutlined />
          </Space>
        </Dropdown>
      </div>
      <Modal open={visible} visible={visible} width={700} footer={null} onCancel={() => setVisible(false)}>
        <HelpInfoModal setVisible={setVisible} />
      </Modal>
    </>
  );
};
export default HeaderMDM;
