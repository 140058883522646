import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {Input, Checkbox, Button, Select, Form, Modal, InputNumber, DatePicker, Popover} from 'antd';
import {get, isString, isUndefined, isEmpty, includes} from 'lodash';

import DynamicSelect from '../FormElements/DynamicSelect';
import DetailTable from '../DetailTable/DetailTable';
import {errorModalCreate} from '../Helpers/Modals';
import Validators from '../Validators';
import {checkFormat, normalizeValues, digitRankFormatter} from '../Helpers/Utils';
import {
  fieldTypes,
  defaultValueCheckbox,
  dateFormats,
  dateTimeFormats,
  defaultValueDate,
  defaultValues,
  formItemLayout,
  CBLayout,
  requiredRule,
  fieldArrayTypes,
} from './helpers/constants';
import {
  setDataForOptionsTypeSelect,
  setDataOptionsReset,
  setReferenceCatalogPatternOriginAC,
} from '../../redux/actions/actions';
import {getValidationRules} from './helpers/utils';
import isURL from 'validator/lib/isURL';

/** Форма создания/Редактирования справочника*/
const EditFieldForm = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    type,
    dataCatalogs,
    editableItem,
    loading,
    status,
    catalogOptions,
    detail,
    uuid,
    referenceCatalogPatternCaption,
  } = props;
  /** Поля для типа поля Список и Множественный выбор */
  const detailSubmitData = !isEmpty(editableItem) ? editableItem?.options.combobox_options : [];
  const [modalVisible, setModalVisible] = useState(false);
  const [comboboxData, setComboboxData] = useState(detailSubmitData);
  /** Получаем uuid справочника */
  const [uuidCatalog, setUuidCatalog] = useState('');
  /** Получаем Origin названия справочника */
  const [referenceCatalogPatternOrigin, setReferenceCatalogPatternOrigin] = useState('');
  /** Получаем значения валидации поля */
  const validator = useSelector((state) => state?.detail.validatorLength);
  /** Получаем валидацию для поля fieldTypeId=2  */
  const numericComparison = useSelector((state) => state?.detail.numericComparison);

  const isDisabled = status === 'Применено';
  /** Отправляем origin справочника в хранилище */
  useEffect(() => {
    !isEmpty(editableItem) &&
      isEmpty(referenceCatalogPatternOrigin) &&
      isEmpty(referenceCatalogPatternCaption) &&
      dispatch(setReferenceCatalogPatternOriginAC(editableItem.referenceCatalogPattern?.split(';')[1]));
  }, [dispatch, editableItem, referenceCatalogPatternOrigin, referenceCatalogPatternCaption]);
  /** Функция для изменения значения справочника */
  const handleReferenceCatalogChange = (value, option) => {
    form.setFieldsValue({
      referenceCatalog: value,
      referenceCatalogPattern: null,
      uuidItem: setUuidCatalog(option.uuidValue),
    });
  };
  /** Функция для валидации значения по умолчанию при указанном количестве символов */
  useEffect(() => {
    !validator && getValidationRules(validator);
  }, [validator]);
  /** Функция для изменения значения аттрибутов справочника */
  const handleReferenceCatalogPatternChange = (value, option) => {
    dispatch(setReferenceCatalogPatternOriginAC(option.fieldOrigin));
    setReferenceCatalogPatternOrigin(option.fieldOrigin);
    form.setFieldsValue({
      referenceCatalogPattern: value,
    });
  };
  /** Получаем Uuid выбранного справочника  */
  const findCaptionFromUuid = () => {
    const itemData = dataCatalogs?.find((item) => item?.uuid === editableItem?.referenceCatalog?.uuid);
    return itemData?.caption;
  };

  const handleTypeChange = (value) => {
    let values = {};
    switch (value) {
      case 8:
      case 7:
        const defaultValueType = form.getFieldValue('date_default_value_type');
        if (defaultValueType === 'empty') form.setFieldsValue({date_duration: null, defaultValue: null});
        values = {
          date_format: value === 7 ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY',
          context_search: false,
        };
        break;
      case 10:
        values = {
          context_search: false,
          required: false,
          uniqueIndex: false,
          uniqueSingleIndex: false,
        };
        break;
      case 12:
        values = {
          attribute_search: false,
          context_search: false,
          required: false,
          uniqueIndex: false,
          uniqueSingleIndex: false,
          default_in_list: false,
        };
        break;
      case 14:
      case 15:
        values = {
          uniqueIndex: false,
          uniqueSingleIndex: false,
        };
        break;
      case 22:
      case 23:
        values = {
          uniqueIndex: false,
          uniqueSingleIndex: false,
          importUnique: false,
        };
        break;
      case 24:
      case 25:
        values = {
          uniqueIndex: false,
          uniqueSingleIndex: false,
          importUnique: false,
          context_search: false,
        };
        break;
      case 19:
        values = {
          context_search: false,
          default_in_list: false,
        };
        break;
      case 21:
      case 6:
        values = {
          context_search: false,
        };
        break;
      default:
    }
    if (value !== 4) {
      values.referenceCatalog = null;
      values.referenceCatalogPattern = null;
      values.enabledLower = false;
    }
    if (!includes([1, 2, 11, 4, 10, 14, 21], value)) {
      values.accessValue = false;
      values.enabledLower = false;
    }

    /* Reset validators */
    values.is_Pattern = false;
    values.is_Length = false;
    values.is_Enumeration = false;
    values.is_NumericComparison = false;
    values.validator_Pattern = '';
    values.validator_Length = '';
    values.validator_Enumeration = '';
    values.validator_NumericComparison = '';
    /* Reset validators */

    form.setFieldsValue(values);
  };

  const mapOption = (array) => {
    return array?.map((item) => (
      <Select.Option value={item.key} key={item.key}>
        {item.value}
      </Select.Option>
    ));
  };

  const onFinish = () => {
    const values = {...form.getFieldsValue(true)};
    const {fieldTypeId} = values;
    const {validators, editableItem, changeValidator, changeDraftField, deleteValidator, closeModal} = props;
    let fieldValues = {};
    let createValidatorValues = {};
    let createValidatorOperators = {};
    let editValidatorValues = {};
    let editValidatorOperators = {};
    let editUuid = {};
    let validatorsUuids = [];
    if (values.is_NumericComparison) {
      if (values.validator_operator_NumericComparison === 'range') {
        if (values.validator_NumericComparison_bottom && values.validator_NumericComparison_top) {
          values.validator_NumericComparison = [
            values.validator_NumericComparison_bottom,
            values.validator_NumericComparison_top,
          ];
        }
      }
      delete values.validator_NumericComparison_top;
      delete values.validator_NumericComparison_bottom;
    }
    if (values.hierarchy_field) values.referenceCatalog = null;
    values.referenceCatalog = uuidCatalog;
    values.uuidItem = uuidCatalog;

    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        normalizeValues({values, key, type: 'editFieldForm'});
        let keyArr = key.split('_');
        if (keyArr[0] === 'validator') {
          if (values[key] || values[key] === 0) {
            if (keyArr[1] === 'operator') {
              if (validators.find((elem) => elem.validationType.caption === keyArr[2])) {
                editValidatorOperators[keyArr[2]] = values[key];
              } else {
                createValidatorOperators[keyArr[2]] = values[key];
              }
            } else {
              const validator = validators.find(
                (elem) => elem.validationType && elem.validationType.caption === keyArr[1]
              );
              if (validator) {
                editValidatorValues[keyArr[1]] = values[key];
                editUuid[keyArr[1]] = validator.uuid;
                validatorsUuids.push(validator.uuid);
              } else {
                createValidatorValues[keyArr[1]] = values[key];
              }
            }
          }
        } else if (keyArr[0] !== 'is') {
          fieldValues[key] = values[key];
        }
      }
    }
    if (editableItem) {
      if (validatorsUuids.length !== validators.length) {
        validators.forEach((item) => {
          if (validatorsUuids.length === 0) deleteValidator(editableItem.uuid, item.uuid);
          else if (!validatorsUuids.some((elem) => elem === item.uuid)) deleteValidator(editableItem.uuid, item.uuid);
        });
      }
      if (!isEmpty(createValidatorValues))
        changeValidator(createValidatorValues, createValidatorOperators, editableItem.uuid, {});
      changeValidator(editValidatorValues, editValidatorOperators, editableItem.uuid, editUuid);
    }
    if (fieldTypeId !== 8 && fieldTypeId !== 7) {
      delete values.date_format;
      delete values.date_default_value_type;
      delete values.date_duration;
    }
    if (fieldTypeId !== 14) {
      delete values.control_type;
    }
    if (fieldTypeId !== 14 && fieldTypeId !== 15) {
      delete values.combobox_options;
    }

    if (detail) {
      delete values.accessValue;
      delete values.enabledLower;
    }

    changeDraftField(
      values,
      editableItem ? 'edit' : 'create',
      !editableItem && {createValidatorValues, createValidatorOperators},
      () => {
        closeModal('editFieldModalVisible');
      }
    );
    isEmpty(editableItem) && dispatch(setDataOptionsReset());
  };
  /** Функция сбора данных  для отправки при добавления записей в список */
  const detailSubmit = (values) => {
    const {value, key} = values;
    let duplicateValues = comboboxData?.find((elem) => elem.value === value);
    let duplicateKeys = comboboxData?.find((elem) => elem.key === key);
    if (!isEmpty(duplicateValues)) {
      errorModalCreate('Такое значение уже существует');
      return false;
    }
    if (!isEmpty(duplicateKeys)) {
      errorModalCreate('Такой ключ уже существует');
      return false;
    }
    dispatch(setDataForOptionsTypeSelect(!isEmpty(comboboxData) ? [...comboboxData, values] : [values]));
    setComboboxData(!isEmpty(comboboxData) ? [...comboboxData, values] : [values]);
  };
  /** Функция сеттер значений списка  */
  const okModal = () => {
    setModalVisible(false);
    form.setFieldsValue({combobox_options: comboboxData});
  };

  const getOptionsValue = (path, defaultValue = false, stringVal = false) => {
    if (editableItem) {
      const value = get(editableItem, `options.${path}`);
      if (value && stringVal) return isString(value) ? value : JSON.stringify(value);
      else return value;
    } else {
      return defaultValue;
    }
  };

  const getValidationData = (id, withOperator, type) => {
    const {validators} = props;
    let validator = validators && validators.find((elem) => elem.validationType?.id === id);
    if (validator?.rule) {
      if (withOperator) {
        let rule = JSON.parse(validator.rule);
        let keys = Object.keys(rule);
        switch (type) {
          case 'operator':
            return keys[0];
          case 'value':
            return rule[keys[0]];
          case 'topValue':
            return rule[keys[0]][0];
          case 'bottomValue':
            return rule[keys[0]][1];
          default:
            return validator;
        }
      } else {
        if (type === 'value') {
          return validator.rule;
        } else {
          return validator;
        }
      }
    }
    return validator;
  };

  const getDefaultValue = ({editableItem, defaultValue}) => {
    if (editableItem) {
      if (editableItem.defaultValue) {
        const {defaultValue} = editableItem;
        switch (editableItem.fieldType.id) {
          case 7:
          case 8:
            return moment(defaultValue);
          case 15:
            return isString(defaultValue) ? JSON.parse(defaultValue) : defaultValue;
          default:
            return editableItem.defaultValue;
        }
      } else return null;
    } else if (defaultValue) return defaultValue;
    else return undefined;
  };

  const renderDateAdditionalFields = (dateFormats, defaultOptions, withTime = false) => {
    const dateFormatsOpt = mapOption(dateFormats);
    return (
      <>
        <Form.Item label="Формат значения" name="date_format" rules={requiredRule}>
          <Select disabled={isDisabled}>{dateFormatsOpt}</Select>
        </Form.Item>
        <Form.Item label="Значение по умолчанию" name="date_default_value_type" rules={requiredRule}>
          <Select
            disabled={isDisabled}
            onChange={(value) => {
              if (value === 'empty')
                form.setFieldsValue({
                  date_duration: null,
                  defaultValue: null,
                });
            }}
          >
            {defaultOptions}
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevState, nextState) =>
            prevState.date_default_value_type !== nextState.date_default_value_type ||
            prevState.date_format !== nextState.date_format
          }
        >
          {({getFieldValue}) => {
            const value = getFieldValue('date_default_value_type');
            let dateFormat = getFieldValue('date_format');
            let pickerType;
            switch (dateFormat) {
              case 'MM YYYY':
                pickerType = 'month';
                dateFormat = 'MM.YYYY';
                break;
              case 'C YYYY':
                pickerType = 'quarter';
                dateFormat = null;
                break;
              case 'DD.MM.YYYY HH:mm:ss':
                withTime = {format: 'HH:mm:ss'};
                break;
              case 'DD.MM.YYYY HH:mm':
                withTime = {format: 'HH:mm'};
                break;
              default:
            }
            return value === 'date' ? (
              <Form.Item label="Календарных дней от текущей даты" name="date_duration" rules={requiredRule}>
                <InputNumber disabled={isDisabled} />
              </Form.Item>
            ) : (
              value === 'specific' && (
                <Form.Item label="Значение" name="defaultValue" rules={requiredRule}>
                  <DatePicker format={dateFormat} picker={pickerType} showTime={withTime} disabled={isDisabled} />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
      </>
    );
  };

  const defaultValueDateOptions = mapOption(defaultValueDate);

  const {
    fieldTypeId,
    fieldOrder,
    origin,
    caption,
    description,
    defaultValue,
    context_search,
    attribute_search,
    default_in_list,
    gui_editable,
    required,
    uniqueIndex,
    old,
    mask,
    referenceCatalog,
    referenceCatalogPattern,
    filter,
    control_type,
    combobox_options,
    date_format,
    date_default_value_type,
    date_duration,
    hierarchy_field,
    rules,
    multilevel_link,
    phone_mask,
    reference_value_script,
    input_mask,
    enabledLower,
    accessValue,
    importUnique,
    uniqueSingleIndex,
  } = defaultValues;

  /** Данные для отображения формы редактирование */
  const initialValues = {
    fieldTypeId: editableItem ? type : fieldTypeId,
    fieldOrder: editableItem ? editableItem.fieldOrder : fieldOrder,
    origin: editableItem ? editableItem.origin : origin,
    caption: editableItem ? editableItem.caption : caption,
    description: editableItem ? editableItem.description : description,
    defaultValue: getDefaultValue({editableItem, defaultValue}),
    context_search: getOptionsValue('context_search', context_search),
    attribute_search: getOptionsValue('attribute_search', attribute_search),
    default_in_list: getOptionsValue('default_in_list', default_in_list),
    gui_editable: getOptionsValue('gui_editable', gui_editable),
    required: editableItem ? editableItem.required : required,
    uniqueIndex: editableItem ? editableItem.uniqueIndex : uniqueIndex,
    uniqueSingleIndex: editableItem ? editableItem.uniqueSingleIndex : uniqueSingleIndex,
    old: getOptionsValue('old', old),
    mask: editableItem ? editableItem.mask : mask,
    referenceCatalogPattern:
      !isEmpty(referenceCatalogPatternCaption) && !isEmpty(editableItem)
        ? referenceCatalogPatternCaption
        : referenceCatalogPattern,
    referenceCatalog: editableItem ? findCaptionFromUuid() : referenceCatalog,
    filter: getOptionsValue('filter', filter, true),
    control_type: getOptionsValue('control_type', control_type),
    combobox_options: getOptionsValue('combobox_options', combobox_options),
    date_format: getOptionsValue('date_format', date_format),
    date_default_value_type: getOptionsValue('date_default_value_type', date_default_value_type),
    date_duration: getOptionsValue('date_duration', date_duration),
    input_mask: getOptionsValue('input_mask', input_mask) || input_mask,
    hierarchy_field: getOptionsValue('hierarchy_field', hierarchy_field),
    multilevel_link: getOptionsValue('multilevel_link', multilevel_link),
    is_Pattern: !!getValidationData(3, false),
    validator_Pattern: !getValidationData(3, false) ? '' : getValidationData(3, false, 'value'),
    is_Length: !!getValidationData(2, true),
    validator_operator_Length: getValidationData(2, true, 'operator'),
    validator_Length: getValidationData(2, true, 'value'),
    is_NumericComparison: !!getValidationData(4, true),
    validator_operator_NumericComparison: getValidationData(4, true, 'operator'),
    validator_NumericComparison: getValidationData(4, true, 'value'),
    validator_NumericComparison_bottom: getValidationData(4, true, 'topValue'),
    validator_NumericComparison_top: getValidationData(4, true, 'bottomValue'),
    is_Enumeration: !!getValidationData(1, false),
    validator_Enumeration: getValidationData(1, false, 'value'),
    rules: getOptionsValue('rules', rules, true),
    phone_mask: getOptionsValue('phone_mask', phone_mask),
    reference_value_script: getOptionsValue('reference_value_script', reference_value_script, true),
    enabledLower: editableItem ? editableItem.enabledLower : enabledLower,
    accessValue: editableItem ? editableItem.accessValue : accessValue,
    importUnique: editableItem ? editableItem.importUnique : importUnique,
    valuesArray: editableItem ? fieldArrayTypes.find((field) => field.value === type) : false,
  };
  const fieldOptionsRender =
    catalogOptions &&
    catalogOptions?.show_field_options?.map((item) => {
      const validateRules = [
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            } else {
              try {
                JSON.parse(value);
                return Promise.resolve();
              } catch (error) {
                return Promise.reject('Неверный формат JSON');
              }
            }
          },
        },
      ];

      const type = item?.fieldType;
      const defaultValue = getOptionsValue(item.option, defaultValues[item.option]);

      if (type === 'boolean' && isUndefined(defaultValue)) initialValues[item.option] = false;
      else initialValues[item.option] = defaultValue ? JSON.stringify(defaultValue) : defaultValue;

      let children;
      switch (type) {
        case 'boolean':
          children = <Checkbox name={item.option} />;
          break;
        case 'integer':
          children = <InputNumber />;
          break;
        default:
          children = <Input.TextArea />;
      }
      return (
        <Form.Item
          key={item.option}
          name={item.option}
          valuePropName={type === 'boolean' && 'checked'}
          labelCol="10"
          label={item.fieldName}
          rules={type !== 'integer' && type !== 'boolean' && validateRules}
        >
          {children}
        </Form.Item>
      );
    });

  const fieldTypeOptions = (fieldTypes) =>
    fieldTypes.map((item, index) => {
      if (editableItem && item.value === 12) {
        return null;
      } else {
        return (
          <Select.Option key={index} value={item.value}>
            {item.label}
          </Select.Option>
        );
      }
    });
  return (
    <>
      <Form {...formItemLayout} form={form} name="field-add" onFinish={onFinish} initialValues={initialValues}>
        <Form.Item {...CBLayout} name="valuesArray" valuePropName="checked">
          <Checkbox
            onChange={() => {
              form.setFieldsValue({
                uniqueIndex: false,
                uniqueSingleIndex: false,
                importUnique: false,
              });
            }}
          >
            Массив значений
          </Checkbox>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevState, nextState) => {
            if (prevState.valuesArray !== nextState.valuesArray) {
              const defaultValue = nextState.valuesArray ? nextState.valuesArray?.value : 1;
              form.setFieldValue('fieldTypeId', defaultValue);
              return prevState.valuesArray !== nextState.valuesArray;
            }
            return prevState.hierarchy_field !== nextState.hierarchy_field;
          }}
        >
          {({getFieldValue}) => {
            const hierarchyField = getFieldValue('hierarchy_field');
            const valuesArray = getFieldValue('valuesArray');
            const fieldCurrentTypes = valuesArray ? fieldArrayTypes : fieldTypes;
            return (
              <Form.Item label="Тип" name="fieldTypeId" {...formItemLayout} rules={requiredRule}>
                <Select
                  style={{width: '100%'}}
                  disabled={isDisabled || hierarchyField}
                  onChange={(value) => handleTypeChange(value)}
                >
                  {fieldTypeOptions(fieldCurrentTypes)}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label="Наименование в БД"
          name="origin"
          extra="Формат camelCase.Допустимо использовать английские буквы, цифры.На первом месте буква"
          rules={[...requiredRule, {validator: checkFormat}]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>
        <Form.Item label="Наименование" name="caption" rules={requiredRule}>
          <Input disabled={isDisabled} />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <Input.TextArea disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          label={
            <Popover content="Порядок расположения атрибута на форме просмотра записи справочника. Чем больше - тем ниже.">
              {'Порядок расположения'}
            </Popover>
          }
          name="fieldOrder"
        >
          <InputNumber decimalSeparator={','} formatter={(value) => digitRankFormatter(value)} />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevState, nextState) => {
            const {setFieldsValue} = form;
            if (prevState.fieldTypeId !== nextState.fieldTypeId) {
              setFieldsValue({defaultValue: nextState.fieldTypeId === 15 ? [] : null});
            }
            return prevState.fieldTypeId !== nextState.fieldTypeId;
          }}
        >
          {({getFieldValue, setFieldsValue}) => {
            const type = getFieldValue('fieldTypeId');
            switch (type) {
              case 1:
              case 11:
              case 22:
              case 23:
                return (
                  <>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevState, nextState) => prevState.phone_mask !== nextState.phone_mask}
                    >
                      {({getFieldValue}) => {
                        const disabledMask = getFieldValue('phone_mask');
                        const type = getFieldValue('fieldTypeId');

                        switch (type) {
                          case 23:
                            return '';
                          default:
                            return (
                              <Form.Item label="Маска" name="input_mask" extra="Регулярное выражение javaScript">
                                <Input disabled={disabledMask} />
                              </Form.Item>
                            );
                        }
                      }}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevState, nextState) => prevState.fieldTypeId !== nextState.fieldTypeId}
                    >
                      {({getFieldValue}) => {
                        const type = getFieldValue('fieldTypeId');
                        switch (type) {
                          case 1:
                          case 22:
                            return (
                              <Form.Item
                                {...CBLayout}
                                name="phone_mask"
                                valuePropName="checked"
                                extra="Применение стандартной маски телефона доступно при отсутствии универсальной маски"
                              >
                                <Checkbox
                                  disabled={isDisabled}
                                  onChange={({target: {checked}}) => {
                                    if (checked) {
                                      setFieldsValue({
                                        input_mask: null,
                                      });
                                    }
                                  }}
                                >
                                  Применить маску международного телефона
                                </Checkbox>
                              </Form.Item>
                            );
                          default:
                            return '';
                        }
                      }}
                    </Form.Item>
                    {!detail && (
                      <Form.Item {...CBLayout} name="accessValue" valuePropName="checked">
                        <Checkbox disabled={isDisabled}>Доступ по значению атрибута</Checkbox>
                      </Form.Item>
                    )}
                    {/* TODO: MDM-1827 */}
                    {/* <Form.Item label="Значение по умолчанию" name="defaultValue" rules={getValidationRules(validator)}>
                      <Input disabled={isDisabled} showCount />
                    </Form.Item> */}
                  </>
                );
              // case 24:
              //   return (
              //     <Form.Item label="Значение по умолчанию" name="defaultValue" rules={getValidationRules(validator)}>
              //       <Input disabled={isDisabled} showCount />
              //     </Form.Item>
              //   );
              case 2:
              case 21:
                return (
                  <>
                    {!detail && (
                      <Form.Item {...CBLayout} name="accessValue" valuePropName="checked">
                        <Checkbox disabled={isDisabled}>Доступ по значению</Checkbox>
                      </Form.Item>
                    )}
                    <Form.Item
                      label="Значение по умолчанию"
                      name="defaultValue"
                      rules={
                        type === 2
                          ? [
                              {
                                required: false,
                                message: `Допустимое значение - ${type === 2 ? 'целое' : 'десятичное'} число`,
                                pattern: type === 2 ? /^-?[0-9]*$/ : /^-?[0-9]*[.,]?[0-9]+$/,
                              },
                              {
                                validator: async (_, value) => {
                                  if (
                                    value &&
                                    ((Number(value) !== numericComparison?.validator_NumericComparison &&
                                      numericComparison?.validator_operator_NumericComparison === 'eq') ||
                                      (Number(value) > numericComparison?.validator_NumericComparison &&
                                        numericComparison?.validator_operator_NumericComparison === 'less') ||
                                      (Number(value) < numericComparison?.validator_NumericComparison &&
                                        numericComparison?.validator_operator_NumericComparison === 'more') ||
                                      ((Number(value) >=
                                        Number(numericComparison?.validator_NumericComparison_bottom) &&
                                        Number(value) <= Number(numericComparison?.validator_NumericComparison_top)) ===
                                        false &&
                                        numericComparison?.validator_operator_NumericComparison === 'range'))
                                  ) {
                                    return Promise?.reject(new Error('Введенное значение не соответствует валидации'));
                                  }
                                },
                              },
                            ]
                          : [
                              {
                                required: false,
                                message: `Допустимое значение - ${type === 2 ? 'целое' : 'десятичное'} число`,
                                pattern: type === 2 ? /^[0-9]*$/ : /^[0-9]*[.,]?[0-9]+$/,
                              },
                            ]
                      }
                    >
                      <Input disabled={isDisabled} />
                    </Form.Item>
                  </>
                );
              case 3:
                return (
                  <Form.Item
                    label="Значение по умолчанию"
                    name="defaultValue"
                    rules={[
                      {
                        required: false,
                        message: 'Допустимое значение - число с плавающей точкой',
                        pattern: /^[0-9|.]*$|^[-]*$/,
                      },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                );
              case 4:
                return (
                  <>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevState, nextState) =>
                        prevState.fieldTypeId !== nextState.fieldTypeId ||
                        prevState.multilevel_link !== nextState.multilevel_link ||
                        prevState.accessValue !== nextState.accessValue
                      }
                    >
                      {({getFieldValue, setFieldsValue}) => {
                        const type = getFieldValue('fieldTypeId');
                        const accessValue = getFieldValue('accessValue');
                        switch (type) {
                          case 4:
                            return (
                              <>
                                <Form.Item {...CBLayout} name="hierarchy_field" valuePropName="checked">
                                  <Checkbox
                                    disabled={isDisabled}
                                    onChange={({target: {checked}}) => {
                                      if (checked) {
                                        setFieldsValue({
                                          referenceCatalog: null,
                                          referenceCatalogPattern: null,
                                        });
                                      }
                                    }}
                                  >
                                    Иерархический атрибут
                                  </Checkbox>
                                </Form.Item>
                                <Form.Item {...CBLayout} name="multilevel_link" valuePropName="checked">
                                  <Checkbox
                                    disabled={isDisabled}
                                    onChange={({target: {checked}}) => {
                                      if (checked) {
                                        setFieldsValue({
                                          referenceCatalogPattern: null,
                                        });
                                      }
                                    }}
                                  >
                                    Многоуровневая ссылка
                                  </Checkbox>
                                </Form.Item>
                                {!detail && (
                                  <Form.Item {...CBLayout} name="accessValue" valuePropName="checked">
                                    <Checkbox
                                      disabled={isDisabled}
                                      onChange={({target: {checked}}) => {
                                        if (!checked) {
                                          setFieldsValue({
                                            enabledLower: false,
                                          });
                                        }
                                      }}
                                    >
                                      Доступ по значению
                                    </Checkbox>
                                  </Form.Item>
                                )}
                                {accessValue && !detail && (
                                  <Form.Item {...CBLayout} name="enabledLower" valuePropName="checked">
                                    <Checkbox disabled={isDisabled}>Включая нижестоящие записи</Checkbox>
                                  </Form.Item>
                                )}
                              </>
                            );
                          default:
                            return '';
                        }
                      }}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevState, nextState) => prevState.hierarchy_field !== nextState.hierarchy_field}
                    >
                      {({getFieldValue}) => {
                        const hierarchyField = getFieldValue('hierarchy_field');
                        return (
                          <Form.Item
                            label="Справочник - источник"
                            name="referenceCatalog"
                            rules={[
                              {
                                required: !hierarchyField,
                                message: 'Поле обязательно для заполнения',
                              },
                            ]}
                          >
                            <DynamicSelect
                              style={{width: '100%'}}
                              placeholder="Выбрать"
                              editableItem={editableItem}
                              notFoundContentPlaceholder="Не найдено"
                              filterOption={true}
                              selectedUuid={uuidCatalog}
                              resetOptions={true}
                              disabled={isDisabled || hierarchyField}
                              defaultValue={!editableItem ? referenceCatalog : findCaptionFromUuid()}
                              feachoptions={{
                                referenceStore: 'mdm',
                                inComeOptionUrl: '/api/v1/catalogs',
                                url: '/api/v1/catalogs',
                                method: 'get',
                                catalogOrigin: 'catalogs',
                                displayedField: 'caption',
                                valueField: 'caption',
                                valueSearchName: 'caption',
                                defaultSize: 1000,
                              }}
                              handleSelectChange={(value, option) => handleReferenceCatalogChange(value, option)}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>

                    <Form.Item
                      shouldUpdate={(prevState, nextState) =>
                        prevState.multilevel_link !== nextState.multilevel_link ||
                        prevState.hierarchy_field !== nextState.hierarchy_field
                      }
                      noStyle
                    >
                      {({getFieldValue}) => {
                        const multilevelLink = getFieldValue('multilevel_link');
                        const referenceCatalog = getFieldValue('referenceCatalog');
                        const hierarchyField = getFieldValue('hierarchy_field');
                        return (
                          <Form.Item
                            label="Атрибут для отображения"
                            name="referenceCatalogPattern"
                            rules={[
                              {
                                required: !multilevelLink,
                                message: 'Поле обязательно для заполнения',
                              },
                            ]}
                          >
                            <DynamicSelect
                              style={{width: '100%'}}
                              placeholder="Выбрать"
                              filterOption={true}
                              resetOptions={true}
                              notFoundContentPlaceholder="Не найдено"
                              selectedUuid={uuidCatalog}
                              disabled={isDisabled || multilevelLink || (isEmpty(referenceCatalog) && !hierarchyField)}
                              feachoptions={{
                                referenceStore: 'mdm',
                                url: `${
                                  hierarchyField
                                    ? `/api/v1/catalog-draft/${uuid}/fields`
                                    : `/api/v1/catalogs/${uuidCatalog || editableItem?.referenceCatalog?.uuid}/fields`
                                }`,
                                method: 'get',
                                catalogOrigin: uuidCatalog,
                                displayedField: 'caption',
                                valueField: 'caption',
                                valueSearchName: 'caption',
                                defaultSize: 1000,
                              }}
                              handleSelectChange={(value, option) => handleReferenceCatalogPatternChange(value, option)}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                    <Form.Item label="Фильтрация значений" name="filter">
                      <Input.TextArea rows={10} disabled={isDisabled} />
                    </Form.Item>
                    <Form.Item label="Скрипт для вычисления значения" name="reference_value_script">
                      <Input.TextArea rows={10} disabled={isDisabled} />
                    </Form.Item>
                  </>
                );
              case 13:
                return (
                  <Form.Item
                    label="Значение по умолчанию"
                    name="defaultValue"
                    rules={[
                      {
                        validator: (_rule, value) => {
                          if (value && !isURL(value)) {
                            return Promise.reject('Неверный формат URL');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                );
              case 6:
                return (
                  <Form.Item label="Значение по умолчанию" name="defaultValue">
                    <Input disabled={isDisabled} />
                  </Form.Item>
                );
              case 14:
                return (
                  <>
                    <Form.Item label="Список значений" name="combobox_options" rules={requiredRule}>
                      <Button onClick={() => setModalVisible(true)}>
                        {comboboxData && comboboxData.length > 1 ? 'Изменить' : 'Добавить'}
                      </Button>
                    </Form.Item>
                    <Form.Item label="Значение по умолчанию" name="defaultValue" extra="Укажите значение по умолчанию">
                      <Select disabled={isDisabled}>{mapOption(comboboxData)}</Select>
                    </Form.Item>
                    {!detail && (
                      <Form.Item {...CBLayout} name="accessValue" valuePropName="checked">
                        <Checkbox disabled={isDisabled}>Доступ по значению </Checkbox>
                      </Form.Item>
                    )}
                  </>
                );
              case 15:
                return (
                  <>
                    <Form.Item label="Список значений" name="combobox_options" rules={requiredRule}>
                      <Button onClick={() => setModalVisible(true)}>Добавить</Button>
                    </Form.Item>
                    <Form.Item label="Значение по умолчанию" name="defaultValue" extra="Укажите значения по умолчанию">
                      <Select mode="multiple" disabled={isDisabled}>
                        {mapOption(comboboxData)}
                      </Select>
                    </Form.Item>
                  </>
                );
              case 8: {
                return renderDateAdditionalFields(dateFormats, defaultValueDateOptions);
              }
              case 7: {
                return renderDateAdditionalFields(dateTimeFormats, defaultValueDateOptions, true);
              }
              case 10:
                return (
                  <>
                    <Form.Item label="Значение по умолчанию" name="defaultValue">
                      <Select disabled={isDisabled}>{mapOption(defaultValueCheckbox)}</Select>
                    </Form.Item>
                    {!detail && (
                      <Form.Item {...CBLayout} name="accessValue" valuePropName="checked">
                        <Checkbox disabled={isDisabled}>Доступ по значению атрибута</Checkbox>
                      </Form.Item>
                    )}
                  </>
                );
              default:
            }
          }}
        </Form.Item>
        <Form.Item label="Правила" name="rules">
          <Input.TextArea rows={2} disabled={isDisabled} />
        </Form.Item>
        {fieldOptionsRender}
        <Validators mapOption={mapOption} form={form} />
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.fieldTypeId !== next.fieldTypeId}>
          {({getFieldValue}) => {
            const value = getFieldValue('fieldTypeId');
            const disabledTypes = [2, 3, 6, 7, 8, 10, 12, 19, 21, 23, 24];
            return (
              <Form.Item {...CBLayout} name="context_search" valuePropName="checked">
                <Checkbox disabled={isDisabled || includes(disabledTypes, value)}>
                  Используется для контекстного поиска
                </Checkbox>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.fieldTypeId !== next.fieldTypeId}>
          {({getFieldValue}) => {
            const value = getFieldValue('fieldTypeId');
            const disabledTypes = [12, 19];
            return (
              <Form.Item {...CBLayout} name="default_in_list" valuePropName="checked">
                <Checkbox disabled={isDisabled || includes(disabledTypes, value)}>
                  Отображать в списковой форме по умолчанию
                </Checkbox>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item {...CBLayout} name="gui_editable" valuePropName="checked">
          <Checkbox disabled={isDisabled}>Редактируемое через GUI</Checkbox>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.fieldTypeId !== next.fieldTypeId}>
          {({getFieldValue}) => {
            const value = getFieldValue('fieldTypeId');
            // TODO: MDM-1827 добавлены типы 22, 23, 24, 25
            const disabledTypes = [10, 12, 22, 23, 24, 25];
            return (
              <Form.Item {...CBLayout} name="required" valuePropName="checked">
                <Checkbox disabled={includes(disabledTypes, value)}>Обязательное на уровне БД</Checkbox>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevState, nextState) => prevState.fieldTypeId !== nextState.fieldTypeId}>
          {({getFieldValue}) => {
            const value = getFieldValue('fieldTypeId');
            const disabledTypes = [12, 22, 23, 24, 25];
            return (
              <Form.Item {...CBLayout} name="importUnique" valuePropName="checked">
                <Checkbox disabled={includes(disabledTypes, value)}>Уникальное при импорте</Checkbox>
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item
          {...CBLayout}
          style={{marginBottom: 0}}
          shouldUpdate={(prevState, nextState) => prevState.fieldTypeId !== nextState.fieldTypeId}
        >
          {({getFieldValue, setFieldsValue}) => {
            const value = getFieldValue('fieldTypeId');
            const disabledTypes = [10, 12, 14, 15, 22, 23, 24, 25];
            return (
              <>
                <strong>Уникальность атрибута</strong>
                <div className="d-flex">
                  <Form.Item className="mr-2" name="uniqueSingleIndex" valuePropName="checked">
                    <Checkbox
                      onChange={({target: {checked}}) => {
                        if (checked) {
                          setFieldsValue({
                            uniqueIndex: false,
                          });
                        }
                      }}
                      disabled={isDisabled || includes(disabledTypes, value)}
                    >
                      Уникальный атрибут
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="uniqueIndex" valuePropName="checked">
                    <Checkbox
                      onChange={({target: {checked}}) => {
                        if (checked) {
                          setFieldsValue({
                            uniqueSingleIndex: false,
                          });
                        }
                      }}
                      disabled={isDisabled || includes(disabledTypes, value)}
                    >
                      Группа уникальных атрибутов
                    </Checkbox>
                  </Form.Item>
                </div>
              </>
            );
          }}
        </Form.Item>

        <Form.Item {...CBLayout} name="old" valuePropName="checked">
          <Checkbox disabled={isDisabled}>Устаревший атрибут</Checkbox>
        </Form.Item>
        {!isDisabled && (
          <div className="d-flex justify-content-end ">
            <Button className="mr-3" type="danger" onClick={() => props.closeModal('editFieldModalVisible')}>
              Отмена
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Сохранить
            </Button>
          </div>
        )}
        <Modal
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
          }}
          width={800}
          destroyOnClose={true}
          footer={null}
        >
          <DetailTable
            disabled={isDisabled}
            data={!isEmpty(comboboxData) && comboboxData}
            setComboboxData={setComboboxData}
            okModal={okModal}
            onCancel={() => {
              setModalVisible(false);
            }}
            detailSubmit={detailSubmit}
          />
        </Modal>
      </Form>
    </>
  );
};

export default React.memo(EditFieldForm);
